const detectionItems = [
    {
        question: `How long does it take ${process.env.REACT_APP_NAME} to approve each order?`,
        answer: "All orders are processed automatically. So customers get immediate results instantly, typically in 2-3 minutes." +
            "About 95% orders are approved.Unapproved orders require in-house further review mannually."
    },
    {
        question: 'What percentage of your orders are declined?',
        answer: "The order approval rate varies based on the industries and risk levels." +
            "For instance, certain sectors experience an impressive 97.7% approval rate, while others achieve 91.5% approval." +
            "Overall, our customer orders have a robust 95.3% approval rate, leaving only a minimal 4.7% of orders declined."
    },
    {
        question: 'What should I do in case of a chargeback?',
        answer: "In case of a chargeback, you can flag this bad customer to prevent future frauds." +
            "Moreover, we have a global block list. Please contribute to this global list" +
            "by emailing following documents to support@spofraud.app:\n" +
            "•  Chargeback notification \n" +
            "•  Proof of delivery \n" +
            "•  Order invoice \n" +
            "•  Or other evidence with the customers \n" +
            "We maintain this global block list to protect the whole community."
    },
    {
        question: 'What information do you use to check against fraud?',
        answer: 'All orders get through the ordeal of our advanced AI technology, which checks information from multiple data areas.These include:' +
            '•  Email address \n' +
            '•  IP location \n' +
            '•  Address \n' +
            '•  Shopping behavior \n' +
            '•  Order history\n' +
            '•  Issuing payment\n'
    }
];

const integrationItems = [
    {
        question: 'Which platforms do you integrate with?',
        answer: 'Currently, we have integrated with Bigcommerce. We will continue to integrate with Shopify soon.'
    },
    {
        question: 'How long does the integration take?',
        answer: 'Our straightforward extensions seamlessly integrate with widely-used platforms, requiring only a few minutes for installation.'
    },
    {
        question: "How do you assure the security of merchants' data",
        answer: 'We encrypt customers business and sensitive information, and keep them private based on industry standards.' +
            'You can check our privacy policy here at sporfraud.app/privacy-policy.'
    }
];

const pricingItems = [
    {
        question: 'How does pricing work?',
        answer: 'We provide competitive flat-rate pricing. With free plan, there is no cost at all. With piaid plans, each order processing only costs less then 2 cents on average.'
    },
    {
        question: 'Do you have free plan?',
        answer: 'Yes, we do provide a free tier service, which can process up to 100 orders.'
    },
    {
        question: 'Can I change plan, if so how to change plan?',
        answer: 'It is easy to change plan. Just click on "update plan" in the billing page.' +
            'It takes effects immediately for upgrading plan.The prorated amount will be typically reflected at the receipt of next biling cycle.' +
            'For downgrading, it will change at the end of the billing cycle of your original plan.'
    },
    {
        question: 'Can I change the billing cyle of the subscription, for example, from month to year?',
        answer: 'Yes. Whether it takes effect immediately depends on whether it is downgrading or upgrading.' +
            'If you just change the billing cycle without changing the plan or service tier, it will takes effect immediately.' +
            'From year to month, the effect will take place at the end of your subscription.'
    },
    {
        question: 'Can I cancel a plan?',
        answer: 'Yes. You can still use the service before the end of billing cycle. After that, your plan will be cancelled.'
    }
];

module.exports = { detectionItems, integrationItems, pricingItems };
